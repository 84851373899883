import React, { useState } from 'react';

const DonationForm = () => {
  const [amount, setAmount] = useState('');
  const predefinedAmounts = ['1000', '5000', '10000', '25000'];

  return (
    <div className="bg-white p-8 rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold mb-6">Make a Donation</h3>
      <div className="grid grid-cols-4 gap-4 mb-6">
        {predefinedAmounts.map((preset) => (
          <button
            key={preset}
            onClick={() => setAmount(preset)}
            className={`py-2 rounded-md border ${
              amount === preset
                ? 'bg-primary text-white border-primary'
                : 'border-gray-300 hover:border-primary'
            }`}
          >
            Rs. {preset}
          </button>
        ))}
      </div>
      <div className="mb-6">
        <label className="block text-gray-700 mb-2">Custom Amount</label>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
          placeholder="Enter amount"
        />
      </div>
      <div className="mb-6">
        <h4 className="font-semibold mb-4">Payment Method</h4>
        <div className="grid grid-cols-2 gap-4">
          <button className="border p-4 rounded-md hover:border-primary flex items-center justify-center">
            <span className="mr-2">💳</span>
            Card Payment
          </button>
          <button className="border p-4 rounded-md hover:border-primary flex items-center justify-center">
            <span className="mr-2">🏦</span>
            Bank Transfer
          </button>
        </div>
      </div>
      <button className="w-full bg-primary text-white py-3 rounded-md hover:bg-primary-dark">
        Proceed to Payment
      </button>
    </div>
  );
};

const AidRequestForm = () => {
  const categories = ['Medical Aid', 'Education Support', 'Emergency Relief'];

  return (
    <div className="bg-white p-8 rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold mb-6">Request Assistance</h3>
      <form className="space-y-6">
        <div>
          <label className="block text-gray-700 mb-2">Full Name</label>
          <input
            type="text"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700 mb-2">Contact Number</label>
          <input
            type="tel"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700 mb-2">Assistance Category</label>
          <select
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            required
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-gray-700 mb-2">Describe Your Situation</label>
          <textarea
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            rows="4"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="w-full bg-primary text-white py-3 rounded-md hover:bg-primary-dark"
        >
          Submit Request
        </button>
      </form>
    </div>
  );
};

const Donations = () => {
  const [activeTab, setActiveTab] = useState('donate');

  return (
    <section className="py-20" id="donations">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Support & Assistance</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Choose whether you want to make a donation or request assistance.
          </p>
        </div>

        <div className="max-w-4xl mx-auto">
          <div className="flex mb-8">
            <button
              onClick={() => setActiveTab('donate')}
              className={`flex-1 py-4 text-center font-semibold ${
                activeTab === 'donate'
                  ? 'border-b-2 border-primary text-primary'
                  : 'text-gray-600'
              }`}
            >
              Make a Donation
            </button>
            <button
              onClick={() => setActiveTab('request')}
              className={`flex-1 py-4 text-center font-semibold ${
                activeTab === 'request'
                  ? 'border-b-2 border-primary text-primary'
                  : 'text-gray-600'
              }`}
            >
              Request Help
            </button>
          </div>

          {activeTab === 'donate' ? <DonationForm /> : <AidRequestForm />}

          <div className="mt-8 bg-gray-50 p-6 rounded-lg">
            <h4 className="font-semibold mb-4">Transparency</h4>
            <p className="text-gray-600">
              We ensure 100% of your donation reaches those in need. Our financial reports
              and impact assessments are published quarterly.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Donations;
