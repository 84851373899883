import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Hero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleDonateClick = () => {
    window.location.href = '#donate';
  };

  const handleRequestHelpClick = () => {
    window.location.href = '#contact';
  };

  const slides = [
    {
      image: 'https://images.unsplash.com/photo-1593113598332-cd288d649433?auto=format&fit=crop&q=80',
      title: 'Serving Humanity',
      description: 'Join us in our mission to help those in need and create positive change in our community.',
      stats: [
        { label: 'Families Helped', value: '500+' },
        { label: 'Volunteers', value: '100+' },
        { label: 'Projects', value: '50+' },
        { label: 'Cities', value: '10+' }
      ]
    },
    {
      image: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?auto=format&fit=crop&q=80',
      title: 'Healthcare for All',
      description: 'Providing medical assistance and healthcare services to underserved communities.',
      stats: [
        { label: 'Patients Treated', value: '100+' },
        { label: 'Medical Camps', value: '50+' },
        { label: 'Hospitals', value: '10+' },
        { label: 'Doctors', value: '100+' }
      ]
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    beforeChange: (_, newIndex) => setCurrentSlide(newIndex)
  };

  return (
    <section className="relative min-h-[60vh] md:min-h-screen overflow-hidden" id="hero">
          <style>
        {`
          @media (max-width: 768px) {
            .slick-dots {
              top: 47% !important;
              transform: translateY(-50%);
              bottom: unset !important;
            }
          }
        `}
      </style>
      <Slider {...settings} className=" md:h-screen h-[10vh]">
        {slides.map((slide, index) => (
          <><div key={index} className="relative h-[60vh] md:h-screen">
            <div
              className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat transition-transform duration-700"
              style={{
                backgroundImage: `url(${slide.image})`,
                transform: `scale(${currentSlide === index ? '1.1' : '1'})`,
                transition: 'transform 6s ease-in-out'
              }}
            >
              <div className="absolute inset-0 bg-black opacity-60"></div>
            </div>

            <div className="relative z-10 h-full flex items-end lg:items-center">
              <div className="container mx-auto px-4">
                <div className="max-w-4xl mx-auto text-center text-white">
                  <h1 className="hidden sm:block text-5xl md:text-6xl font-bold mb-6">{slide.title}</h1>
                  <p className="hidden sm:block text-xl mb-8 max-w-2xl mx-auto">{slide.description}</p>

                  <div className="flex flex-wrap gap-4 justify-center mt-4 ">
                    <button
                      onClick={handleDonateClick}
                      className="bg-primary hover:bg-primary-dark text-white px-6 py-3 rounded-full font-semibold transition-all duration-300 transform hover:scale-105"
                    >
                      Donate Now
                    </button>
                    <button
                      onClick={handleRequestHelpClick}
                      className="bg-white hover:bg-gray-100 text-primary px-6 py-3 rounded-full font-semibold transition-all duration-300 transform hover:scale-105"
                    >
                      Request Help
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div><div className="grid grid-cols-2 gap-2 text-center text-white mt-6 md:hidden p-2">
              {slides[currentSlide].stats.map((stat, statIndex) => (
                <div key={statIndex} className="bg-gray-800 p-4 rounded-lg">
                  <div className="text-2xl font-bold">{stat.value}</div>
                  <div className="text-sm uppercase tracking-wider">{stat.label}</div>
                </div>
              ))}
            </div></>
        ))}
        
      </Slider>

      
    </section>
  );
};

export default Hero;
