import React from 'react';

const BlogCard = ({ category, title, gradient, excerpt }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden">
    <div className="relative h-48">
      <div
        className="absolute inset-0"
        style={{
          background: gradient,
          opacity: 0.9
        }}
      >
        <span className="absolute top-4 left-4 bg-primary text-white px-3 py-1 rounded-full text-sm">
          {category}
        </span>
      </div>
    </div>
    <div className="p-6">
      <h3 className="text-xl font-semibold mb-3">{title}</h3>
      <p className="text-gray-600">{excerpt}</p>
      <button className="mt-4 text-primary font-semibold hover:text-primary-dark">
        Read More →
      </button>
    </div>
  </div>
);

const Blog = () => {
  const articles = [
    {
      category: 'Health',
      title: 'Medical Camp Success in Rural Areas',
      gradient: 'linear-gradient(45deg, #10B981, #059669)',
      excerpt: 'Our recent medical camp provided free healthcare services to over 200 families...'
    },
    {
      category: 'Education',
      title: 'Supporting Dreams Through Education',
      gradient: 'linear-gradient(45deg, #3B82F6, #1D4ED8)',
      excerpt: 'Meet the students who received scholarships and how it changed their lives...'
    },
    {
      category: 'Zakat',
      title: 'Impact of Your Zakat Contributions',
      gradient: 'linear-gradient(45deg, #8B5CF6, #6D28D9)',
      excerpt: 'See how your Zakat helped transform lives during the holy month of Ramadan...'
    },
    {
      category: 'Relief',
      title: 'Emergency Response: Flood Relief',
      gradient: 'linear-gradient(45deg, #EF4444, #DC2626)',
      excerpt: 'Our teams provided immediate assistance to flood-affected communities...'
    }
  ];

  return (
    <section className="py-20 bg-gray-50" id="blog">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Blog & Success Stories</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Read about our latest initiatives, success stories, and the impact we're making in communities.
          </p>
        </div>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {articles.map((article, index) => (
            <BlogCard key={index} {...article} />
          ))}
        </div>

        <div className="mt-12 text-center">
          <button className="bg-primary hover:bg-primary-dark text-white px-8 py-3 rounded-full font-semibold transition">
            View All Stories
          </button>
        </div>
      </div>
    </section>
  );
};

export default Blog;
