import React from 'react';

const About = () => {
  const features = [
    {
      title: 'Our Mission',
      description: 'To serve humanity with compassion and dedication, providing essential support to those in need regardless of their background.',
      icon: '🌟'
    },
    {
      title: 'Our Vision',
      description: 'Creating a world where every individual has access to basic necessities, education, and healthcare for a dignified life.',
      icon: '👥'
    },
    {
      title: 'Our Values',
      description: 'Integrity, compassion, and dedication guide our every action as we work tirelessly to make a positive impact.',
      icon: '❤️'
    }
  ];

  const milestones = [
    { year: '2018', achievement: 'Foundation Established' },
    { year: '2019', achievement: 'First Medical Camp' },
    { year: '2020', achievement: 'COVID-19 Relief Efforts' },
    { year: '2021', achievement: 'Education Initiative Launch' },
    { year: '2022', achievement: 'Community Center Opening' },
    { year: '2023', achievement: 'Nationwide Expansion' }
  ];

  return (
    <section className="sm:py-10 lg:py-20 bg-gradient-to-b from-gray-50 to-white" id="about">
      <div className="container mx-auto px-4">
        {/* Main Content */}
        <div className="max-w-4xl mx-auto text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-6 text-gray-900">
            About Us
          </h2>
          <p className="text-xl text-gray-600 leading-relaxed ">
            Since our establishment, we have been dedicated to serving humanity through various initiatives
            in healthcare, education, and community development. Our commitment to excellence and
            compassion drives us to make a lasting impact in the lives of those we serve.
          </p>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
              <div className="text-4xl mb-4">{feature.icon}</div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">{feature.title}</h3>
              <p className="text-gray-600 leading-relaxed">{feature.description}</p>
            </div>
          ))}
        </div>

        {/* Timeline Section */}
        <div className="max-w-4xl mx-auto">
          <h3 className="text-3xl font-bold text-center mb-12 text-gray-900">Our Journey</h3>
          <div className="relative">
            {/* Timeline Line */}
            <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-primary opacity-20"></div>
            
            {/* Timeline Items */}
            <div className="space-y-12">
              {milestones.map((milestone, index) => (
                <div 
                  key={index} 
                  className={`flex items-center ${
                    index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'
                  }`}
                >
                  <div className="w-1/2 pr-8 text-right">
                    <div className={`${index % 2 === 0 ? 'text-right' : 'text-left'}`}>
                      <h4 className="text-xl font-bold text-primary mb-2">{milestone.year}</h4>
                      <p className="text-gray-600">{milestone.achievement}</p>
                    </div>
                  </div>
                  <div className="relative flex items-center justify-center w-8">
                    <div className="h-4 w-4 rounded-full bg-primary"></div>
                  </div>
                  <div className="w-1/2 pl-8"></div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Call to Action */}
        <div className="text-center mt-16">
          <p className="text-xl text-gray-600 mb-8">
            Join us in our mission to create positive change in communities across the nation.
          </p>
          <div className="flex justify-center gap-4">
            <button 
              onClick={() => window.location.href = '#volunteer'}
              className="bg-primary hover:bg-primary-dark text-white px-8 py-3 rounded-full font-semibold transition-all duration-300 transform hover:scale-105"
            >
              Join Our Team
            </button>
            <button 
              onClick={() => window.location.href = '#contact'}
              className="border-2 border-primary text-primary hover:bg-primary hover:text-white px-8 py-3 rounded-full font-semibold transition-all duration-300 transform hover:scale-105"
            >
              Get in Touch
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
