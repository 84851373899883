import React, { useState } from 'react';

const FAQItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div className="border-b border-gray-200">
      <button
        className="w-full py-6 text-left flex justify-between items-center focus:outline-none"
        onClick={onClick}
      >
        <span className="text-lg font-semibold">{question}</span>
        <span className={`transform transition-transform ${isOpen ? 'rotate-180' : ''}`}>
          ▼
        </span>
      </button>
      <div
        className={`overflow-hidden transition-all duration-300 ${
          isOpen ? 'max-h-96 mb-6' : 'max-h-0'
        }`}
      >
        <p className="text-gray-600">{answer}</p>
      </div>
    </div>
  );
};

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(0);

  const faqs = [
    {
      question: 'How can I make a donation?',
      answer: 'You can donate through our website using credit/debit cards, bank transfer, or mobile wallets. We also accept donations in person at our office locations.'
    },
    {
      question: 'How is Zakat distributed?',
      answer: 'Zakat funds are distributed according to Islamic principles, primarily focusing on helping the poor, needy, and those in debt. We ensure proper verification of recipients and maintain complete transparency.'
    },
    {
      question: 'What types of assistance do you provide?',
      answer: 'We provide medical aid, educational support, emergency relief, and financial assistance to eligible individuals and families. Each case is evaluated based on need and urgency.'
    },
    {
      question: 'How can I volunteer?',
      answer: 'You can sign up as a volunteer through our website. We have various opportunities including event support, fundraising, and community outreach programs.'
    },
    {
      question: 'How do you ensure transparency?',
      answer: 'We publish regular financial reports, maintain detailed records of all donations and distributions, and are audited by independent firms. All reports are available on our website.'
    },
    {
      question: 'What documents are needed to request assistance?',
      answer: 'Required documents typically include ID proof, income certificate, and relevant supporting documents (medical reports for medical aid, school documents for education support, etc.).'
    }
  ];

  return (
    <section className="py-20 bg-gray-50" id="faq">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Frequently Asked Questions</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Find answers to common questions about our foundation, donations, and assistance programs.
          </p>
        </div>

        <div className="max-w-3xl mx-auto">
          {faqs.map((faq, index) => (
            <FAQItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              isOpen={index === openIndex}
              onClick={() => setOpenIndex(index === openIndex ? -1 : index)}
            />
          ))}
        </div>

        <div className="text-center mt-12">
          <p className="text-gray-600">
            Didn't find what you're looking for?{' '}
            <a href="#contact" className="text-primary font-semibold hover:text-primary-dark">
              Contact us
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
