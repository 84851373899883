import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">About Us</h3>
            <p className="text-gray-400">
              Khidmat-e-Khalq Foundation is dedicated to serving humanity through various charitable initiatives and community development programs.
            </p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a href="#about" className="text-gray-400 hover:text-white">About</a>
              </li>
              <li>
                <a href="#services" className="text-gray-400 hover:text-white">Services</a>
              </li>
              <li>
                <a href="#blog" className="text-gray-400 hover:text-white">Blog</a>
              </li>
              <li>
                <a href="#contact" className="text-gray-400 hover:text-white">Contact</a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Connect</h3>
            <ul className="space-y-2">
              <li>
                <a href="https://facebook.com/khidmatfoundation" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">Facebook</a>
              </li>
              <li>
                <a href="https://twitter.com/khidmatfoundation" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">Twitter</a>
              </li>
              <li>
                <a href="https://instagram.com/khidmatfoundation" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">Instagram</a>
              </li>
              <li>
                <a href="https://linkedin.com/company/khidmatfoundation" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">LinkedIn</a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">Contact</h3>
            <ul className="space-y-2">
              <li className="text-gray-400">
                <a href="tel:+923001234567" className="hover:text-white">+91 8286450114 / 7021504031</a>
              </li>
              <li className="text-gray-400">
                <a href="mailto:info@khidmat.org" className="hover:text-white">khidmatekhalq.kkf@gmail.com</a>
              </li>
              <li className="text-gray-400">
              KKF Head Office, 301 , K.R.N , Dumping Road, Near VK English School, Baiganwadi, Govandi, Mumbai 43
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-800 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Khidmat-e-Khalq Foundation. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
