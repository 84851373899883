import React, { useState, useEffect } from "react";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const menuItems = [
    { label: "Home", href: "#" },
    { label: "About", href: "#about" },
    { label: "Blog", href: "#blog" },
    { label: "FAQ", href: "#faq" },
    { label: "Contact", href: "#contact" },
  ];

  return (
    <nav
      className={`lg:fixed sm:block w-full z-50 transition-colors duration-300 ${isScrolled ? "bg-white shadow-md" : "bg-transparent"
        }`}
    >
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-20">
          <a href="#" className="flex items-center">
            <span className="text-2xl font-bold text-primary">Khidmat-e-Khalq</span>
          </a>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-8">
            {menuItems.map((item) => (
              <a
                key={item.label}
                href={item.href}
                className={`${isScrolled ? "text-black" : "text-white"
                  }  hover:text-primary transition-colors`}
              >
                {item.label}
              </a>
            ))}
            <div className="flex items-center space-x-4">
              <button className={`px-6 py-2 ${isScrolled ? "text-primary" : "text-white" } border ${isScrolled ? "border-primary" : "border-white" }  rounded-full hover:bg-primary hover:text-white transition`}>
                Request Help
              </button>
              <button className="px-6 py-2 bg-primary text-white rounded-full hover:bg-primary-dark transition">
                Donate Now
              </button>
            </div>
          </div>

          {/* Mobile Menu Button */}
          <button className="md:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <svg
              className="w-6 h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMenuOpen ? <path d="M6 18L18 6M6 6l12 12" /> : <path d="M4 6h16M4 12h16M4 18h16" />}
            </svg>
          </button>
        </div>

        {/* Mobile Menu */}
        <div className={`md:hidden ${isMenuOpen ? "block" : "hidden"} pb-4`}>
          {menuItems.map((item) => (
            <a
              key={item.label}
              href={item.href}
              className="block py-2 text-gray-600 hover:text-primary"
              onClick={() => setIsMenuOpen(false)}
            >
              {item.label}
            </a>
          ))}
          <div className="mt-4 space-y-2">
            <button className="w-full py-2 text-primary border border-primary rounded-full hover:bg-primary hover:text-white transition">
              Request Help
            </button>
            <button className="w-full py-2 bg-primary text-white rounded-full hover:bg-primary-dark transition">
              Donate Now
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
